import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252,replace 0,1,2) should always be at the top of the file.
   * AUSDT  variable is busd(BUSD) and WKALIS variable is wbnb (KALIS,WKALIS)
   */
  {
    pid: 0,
    lpSymbol: 'KSHARE',
    lpAddresses: {
      5438: '0xa46e3b4a28ed18d72d5d451db9688eebd4fa1176',
      654: '0xa46e3b4a28ed18d72d5d451db9688eebd4fa1176',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
 
  {
    pid: 1,
    lpSymbol: 'KSHARE-KALIS LP',
    lpAddresses: {
      5438: '',
      654: '0xb43E17b52122e1c6E81482Ca072844982C179033',
    },
    token: tokens.kshare,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 2,
    lpSymbol: 'USDT-KALIS LP',
    lpAddresses: {
      5438: '',
      654: '0x7Ed5077B75e882A92341Bce6a054a40C3ae460aB',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  
  
    
    
  	  

]

export default farms
