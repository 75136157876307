import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  
   {
    sousId: 0,
    stakingToken: tokens.kshare,
    earningToken: tokens.kshare,
    contractAddress: {
      5438: '0xc84e0278A90e16D574A3026083319C8b4b353aC5',
      654: '0xc84e0278A90e16D574A3026083319C8b4b353aC5',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.35',
    sortOrder: 1,
    isFinished: false,
  },    
 
 
 
  
]

export default pools
