export default {
  masterChef: {
    5438: '0xc84e0278A90e16D574A3026083319C8b4b353aC5',
	654: '0xc84e0278A90e16D574A3026083319C8b4b353aC5',
  },
  sousChef: {
    5438: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    654: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lotteryV2: {
    5438: '0x96aadb2977ac505fbf6dbdd0e79dc0a998dc25ce',
    654: '0x7ea9b4d1d87611b17f90a3543c0a2d0b4269ca10',
  },
  multiCall: {
    654: '0x99d1eb4bebd3b8d41da78444ef8e9bc68ad3783f',
    5438: '0x99d1eb4bebd3b8d41da78444ef8e9bc68ad3783f',
  },
  pancakeProfile: {
    654: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    5438: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    654: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    5438: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    654: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    5438: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    654: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    5438: '',
  },
  pointCenterIfo: {
    654: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    5438: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    654: '0xFee8A195570a18461146F401d6033f5ab3380849',
    5438: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetition: {
    654: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    5438: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  easterNft: {
    654: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    5438: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault: {
    654: '0x946cb8b809a691433518c99bedd11357daa7e494',
    5438: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
  },
  predictions: {
    654: '0xe7C277DB56F2089351E9ac910871EB820e11206B',
    5438: '',
  },
  chainlinkOracle: {
    654: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    5438: '0xe491F1D4629d50BaA4cD274663584900B125739C',
  },
  bunnySpecialCakeVault: {
    654: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    5438: '',
  },
  bunnySpecialPrediction: {
    654: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    5438: '',
  },
  bunnySpecialLottery: {
    654: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    5438: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  farmAuction: {
    654: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    5438: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
}
