import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Kalichain - The best AMM DEX on Kalichain Smart Chain (KALI)',
  description:
    'The most popular AMM on kalichain by user count! Earn KSHARE through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by Kalichain), NFTs, and more, on a platform you can trust.',
  image: 'https://dex.kalichain.com/images/swapimg/twitter.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Home')} | ${t('Kalichain')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('Kalichain')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('Kalichain')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('Kalichain')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('Kalichain')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('Kalichain')}`,
      }
    case '/collectibles':
      return {
        title: `${t('Collectibles')} | ${t('Kalichain')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('Kalichain')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('Kalichain')}`,
      }
    case '/profile/tasks':
      return {
        title: `${t('Task Center')} | ${t('Kalichain')}`,
      }
    case '/profile':
      return {
        title: `${t('Your Profile')} | ${t('Kalichain')}`,
      }
    default:
      return null
  }
}
