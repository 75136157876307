import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
 
  
	{
	label: t('Home'),
	icon: 'HomeIcon',
	href: '/',
	},

  
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Swap'),
        href: '/swap?outputCurrency=0xa46e3b4a28ed18d72d5d451db9688eebd4fa1176',
      },
      {
        label: t('Liquidity'),
        href: '/pool',

      },
    ],
  },
  
   {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: '/farms',
  },  
  {
    label: t('Pools'),
    icon: 'PoolIcon',
    href: '/pools',
  }, 
  
  {
	label: t('Docs'),
	icon: 'InfoIcon',
	href: 'https://docs.kalichain.com/',
	}, 
  
 
  


		
		
		
		
		
		
		
		
		
  
  
 
        



]

export default config
