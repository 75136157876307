import { ChainId, Token } from '@pancakeswap/sdk'

export const CAKE: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
}
export const BUSD: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xe8444ce29a2b51c7ca073c4705c363726fd5ffd8',
    18,
    'Teacher USDT',
    'USDT',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
  ),
}
export const WBNB = new Token(ChainId.MAINNET, '0x0d90b0d5567a84b7f5fea456a76952445c0624be', 18, 'KALIS', 'Wrapped wKALIS')
export const DAI = new Token(ChainId.MAINNET, '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', 18, 'DAI', 'Dai Stablecoin')
export const USDT = new Token(ChainId.MAINNET, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'Tether USD')
export const BTCB = new Token(ChainId.MAINNET, '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18, 'BTCB', 'Binance BTC')
export const UST = new Token(
  ChainId.MAINNET,
  '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  18,
  'UST',
  'Wrapped UST Token',
)
export const ETH = new Token(
  ChainId.MAINNET,
  '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  18,
  'ETH',
  'Binance-Peg Ethereum Token',
)
export const USDC = new Token(
  ChainId.MAINNET,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
)

const tokens = {
		kalis: {
		symbol: 'KALIS',
		projectLink: 'https://dex.kalichain.com/',
		},
		
		wkalis: {
		symbol: 'WKALIS',
		address: {
		654: '0x0d90b0d5567a84b7f5fea456a76952445c0624be',
		5438: '0x0d90b0d5567a84b7f5fea456a76952445c0624be',
		},
		decimals: 18,
		projectLink: 'https://dex.kalichain.com/',
		},
		
		
		
	    kshare: {
		symbol: 'KSHARE',
		address: {
		654: '0xa46e3b4a28ed18d72d5d451db9688eebd4fa1176',
		5438: '0xa46e3b4a28ed18d72d5d451db9688eebd4fa1176',
		},
		decimals: 18,
		projectLink: 'https://dex.kalichain.com/',
		},
		
		
 // ***************************************** client token   ********************************** 		
		
		 
		
		

        	
		
		
		

   
 // ***************************************** default token   ********************************** 
 
		cake: {
		symbol: 'CAKE',
		address: {
		654: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
		5438: '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
		},
		decimals: 18,
		projectLink: 'https://pancakeswap.finance/',
		},
 
		wbnb: {
		symbol: 'wKALIS',
		address: {
		654: '0x0d90b0d5567a84b7f5fea456a76952445c0624be',
		5438: '0x0d90b0d5567a84b7f5fea456a76952445c0624be',
		},
		decimals: 18,
		projectLink: 'https://dex.kalichain.com/',
		},
		
		
		busd: {
		symbol: 'USDT',
		address: {
		654: '0xe8444ce29a2b51c7ca073c4705c363726fd5ffd8',
		5438: '',
		},
		decimals: 18,
		projectLink: 'https://www.paxos.com/busd/',
		},

		syrup: {
		symbol: 'SYRUP',
		address: {
		654: '0xa485fc9f634c91568b48a25d24c4a0dc34714f71',
		5438: '0xa485fc9f634c91568b48a25d24c4a0dc34714f71',
		},
		decimals: 18,
		projectLink: 'https://pancakeswap.finance/',
		},

		bondly: {
		symbol: 'BONDLY',
		address: {
		654: '0x96058f8C3e16576D9BD68766f3836d9A33158f89',
		5438: '',
		},
		decimals: 18,
		projectLink: 'https://www.bondly.finance/',
		},

		safemoon: {
		symbol: 'SAFEMOON',
		address: {
		654: '0x8076C74C5e3F5852037F31Ff0093Eeb8c8ADd8D3',
		5438: '',
		},
		decimals: 9,
		projectLink: 'https://safemoon.net/',
		},
  
}


export default tokens
